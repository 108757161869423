<template>
    <span v-html="markup"></span>
</template>

<script>

import htmldiff from '@/libs/htmldiff'

export default {
  props: ['current', 'version'],
  created: async function () {
    this.markup = htmldiff(this.version.replace(/(\r\n|\n|\r)/gm, "<br />"), this.current.replace(/(\r\n|\n|\r)/gm, "<br />"))
  },
  data () {
    return {
      markup: ''
    }
  }
}
</script>

<style>
  del {
    background-color: orangered;
  }
  ins {
    background-color: lightgreen;
  }
</style>
