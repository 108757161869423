<template>
  <span v-html="rendered"></span>
</template>

<script>
export default {
  name: 'NewLines',
  props: ['content'],
  computed: {
    rendered: function () {
      return this.content.replace(/(\r\n|\n|\r)/gm, "<br />")
    }
  }
}
</script>

<style>
</style>
